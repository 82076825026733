<template>
  <div
    id="CancelBooking"
    aria-hidden="true"
    aria-labelledby="staticBackdropLabel"
    class="modal fade"
    data-bs-backdrop="false"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 id="CancelBookingLabel" class="modal-title">
            <small>Cancel Booking</small>
          </h6>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          Your booking cancellation charges are SAR {{ bookingFee }}. Are you
          sure, you want to cancel this booking?
        </div>
        <div
          class="d-flex justify-content-center align-items-center modal-footer"
        >
          <button class="btn btnNo" data-bs-dismiss="modal" type="button">
            No
          </button>
          <button
            class="btn btnYes"
            data-bs-dismiss="modal"
            @click="displayReasonModel"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CancelBooking",
  props: {
    cancel: Function,
    bookingFee: {
      type: String,
    },
  },
  methods: {
    displayReasonModel() {
      $("#cancelBookingReason").modal("toggle");
    },
  },
};
</script>

<style scoped>
.btn-close {
  border-radius: 100%;
}

.modal-content {
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 10px 8px #e5e5e5;
}

.btnNo {
  width: 100px;
  background: #ffffff;
  color: #595f69;
  border-radius: 10px;
  border: 1px solid #e8e9eb;
}

.btnYes {
  width: 100px;
  background: #db0e03;
  color: white;
  border-radius: 10px;
}

.modal-footer {
  border-top: none;
}
</style>
